<template>
    <main class="main-wrapper">
        <div class="grid-row">
            <div class="grid-column-full">
                <h3>Privacy policy</h3>
                <p>Officia nisi laborum ad. Dolore duis enim voluptate occaecat velit. Velit deserunt veniam fugiat nisi
                    laboris esse aute consectetur. Excepteur et sint reprehenderit.</p>
                <p>Nulla duis laborum elit ullamco amet id fugiat consectetur pariatur non nisi cillum deserunt. Culpa
                    voluptate mollit nulla. Qui ut dolore et nisi. Proident exercitation pariatur anim aliqua voluptate ad
                    voluptate non sint ipsum pariatur officia consequat aliquip.</p>
            </div>
        </div>
    </main>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'PrivacyPolicyView',
    components: {
    },
}
</script>