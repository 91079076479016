<template>
    <details class="details">
        <summary class="details__summary">
            <span class="details__summary-text">
                {{ option.label }}
            </span>
        </summary>
        <div class="details__text">{{ option.desc }}</div>
    </details>
</template>

<script>
export default {
    name: 'AccordianItem',
    props: {
        option: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            show: false
        }
    },
}
</script>

<style lang="scss" scoped>

</style>