<template>
    <main class="main-wrapper">
        <div class="grid-row">
            <div class="grid-column-full">
                <h2>404</h2>
                <p>Page not found. Try going back to <a href="/" @click.prevent="$router.push({ name: 'home'})">the
                        homepage</a>.</p>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'HttpStatus404',
}
</script>