<template>
    <div class="inset-text">{{ text }}</div>
</template>

<script>
export default {
    name: 'InsetText',
    components: {
    },
    props: {
        text: {
            type: String,
            required: true,
        }
    },
}
</script>