<template>
    <div class="main-wrapper">
        <div class="navigation">
            <div class="navigation__back">
                <a class="back-link"
                    @click="$router.push({ name: 'calculator', params: { 'question': 6 }})">
                    Back
                </a>
            </div>
        </div>

        <div class="grid-row">
            <div class="grid-column-two-thirds-from-desktop">
                <h2>Summary of the estate</h2>
                <h3>Net estate value: {{ currFormat(total) }}</h3>
                <dl class="summary-list margin-bottom-9">
                    <template v-for="(items, name, index) in state">
                        <summary-table-row-vue 
                            v-for="(item) in items.list"
                            :symbol="(item.type !=='radio_group')? currency : ''" 
                            :item="item" :page="index"
                            :key="name + item.id" />
                    </template>
                </dl>
                <h3 class="govuk-heading-m">Now calculate the results</h3>
                <p class="body">By submitting the values entered above the deceased estate will be calculated accordingly.</p>
                <button 
                    class="button" 
                    type="button" 
                    @click="$router.push({ name: 'results' })"
                >
                    Calculate the results
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { calculations } from '@/mixins/calculations'
import SummaryTableRowVue from '@/components/SummaryTableRow.vue'
export default {
    mixins: [calculations],
    components: { SummaryTableRowVue },
    computed: {
        ...mapGetters({
            state: 'state',
            total: 'total',
        }),
    },
}
</script>

<style lang="scss" scoped>
.navigation {
    display: flex;
    justify-content: space-between;
    min-height: 65px;
}

h3 {
    font-weight: 600;
    margin-bottom: 40px;
}
</style>