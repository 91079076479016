<template>
    <div>
        <p v-if = "!valid" class="error-message">
            <span class="visually-hidden">Error:</span> The amount you enter must be a positive number
        </p>
        <label 
            v-if="item.label && item.category !== 'question'" 
            class="label" 
            :for="slug + index"
        >
            {{ item.label}}
        </label>
        <p v-if="item.desc" class="hint">{{ item.desc }}</p>
        <div class="input__wrapper">
            <div 
                v-if="item.label && item.category !== 'question'"
                class="input__prefix" 
                aria-hidden="true" 
            >
                £
            </div>
            <input
                ref="input" 
                class="input input--width-5"
                :class=" valid ? '' : 'input--error'"
                :id="slug + index" 
                :type="item.type"
                :value="value[slug].list[index].value"
                @input="update(slug, index, $event.target.value)" 
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        canEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        item: {
            type: Object,
            required: false,
        },
        slug: {
            type: String,
            required: false
        },
        displayId: {
            type: Boolean,
            default: false
        },
        index: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            valid: true,
            loaded: false
        }
    },
    computed: {
        ...mapState({
            value: state => state
        }),

    },
    mounted(){
        //collect initial value set from vuex store to display styling
        this.update(this.key, this.index, this.$refs.input.value)
        this.loaded = true
    },
    methods: {
        update(key, index, value) {
            this.valid = ((value >= 0 )) ? true:false
            //checks first load to display valid styling
            if(this.loaded) {
                this.$store.dispatch('update', { type: 'input_group', key, index, value })
            }
        }
    }
}
</script>