import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    estate: {
      total:0,
    },
    // how the above estate is shared
    inheritance: {
      spouse: 0,
      children: 0,
      family:0,
    },
    edited: false
  },
  getters: {
    getList: (state) => (key) => {
      return state[key].list
    },
    getSubTotal: (state) => (key) => {
      return state[key].sub_total
    },
    total(state){
      return state.estate.total
    },
    edited(state){
      return state.edited
    },
    moveable(state){
      return state.moveable
    },
    getEstate(state){
      return state.estate
    },
    getInheritance(state){
      return state.inheritance
    },
    partner(state){
      return state.partner.list[0]
    },
    children(state){
      return state.children.list[0]
    },
    state(state){
      return Object.fromEntries(Object.entries(state).filter(([key]) => {
        if(key !== 'estate' && key !== 'inheritance'){
          const storage = (localStorage.getItem('store'))? JSON.parse(localStorage.getItem('store')) : state[key]
          return storage
        }
      }
      ));
    }
  },
  mutations: {
    initialiseStore(state) {
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
			}
		},
    ADD_STATE (state, payload) {
      Vue.set(state, payload[0], payload[1])
    },
    INIT (state, payload){
      if (sessionStorage.getItem('store')) {
        // Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
      }else{
        state[payload.key].sub_total = 0
        state[payload.key].category = payload.category
        state[payload.key].list.push(payload.value)
      }
    },

    UPDATE (state, payload) {
      if(payload.type === 'radio_group'){
        state[payload.key].list[0].select = payload.label
        state[payload.key].list[0].value = Number(payload.value)
      }

      if(payload.type === 'input_group'){
        state[payload.key].list[payload.index].value = Number(payload.value)
      }
    },
    EDITED(state, val){
      state.edited = val
    },
    SUB_TOTAL (state, key){
      let num = 0
      state[key].list.forEach(item => {
        /**
         * there are 3 categories assets | debts | question
         * assets are added
         * debts are subtracted
         * question do not have sub_totals
         */ 
        if(item.category !== 'question'){   
          if(item.value){
              if(item.category === 'asset')
              {
                num += Number(item.value)
              }else{
                num -= Number(item.value)
              }
          }
        }
      });

      state[key].sub_total = num
    },
    TOTAL(state, num){
      state.estate.total = num
    },
    HERITABLE(state, num){
      state.estate.heritable = num
    },
    MOVEABLE(state){
      state.estate.moveable.total = state.estate.moveable.contents + state.estate.moveable.cash - 60000
    },
    MOVEABLE_CASH(state, num){
      state.estate.moveable.cash = num
    },
    MOVEABLE_CONTENTS(state, num){
      state.estate.moveable.contents = num
    },
    PARTNER_STATUS(state, val){
      state.partner.value = val
    },
    ADD_ITEM(state, payload){
      state[payload.key].list.push(payload.item)
    },
    REMOVE_ITEM(state, payload){
      state[payload.key].list.splice(payload.index, 1)
    },
    RESET(state){
      if(localStorage.getItem('store')){
        for(let item in state){
          if(state[item].list){
            for(let i in state[item].list){
              state[item].list[i].value = 0
            }
          }
          if(state[item].sub_total){
            state[item].sub_total = 0
          }
        }
        state.total = 0
        state.heritable = 0
        state.partner.list[0].value = -1
        state.children.list[0].value = -1
        state.estate.heritable = 0
        state.estate.total = 0
        state.estate.moveable.contents = 0
        state.estate.moveable.cash = 0
        state.estate.moveable.total = 0
        state.inheritance.spouse = 0
        state.inheritance.family = 0
      }
    }
  },
  actions: {
    addState({commit}, payload){
      commit('ADD_STATE', payload)
    },
    init({commit}, payload){
      commit('INIT', payload)
    },
    update({commit}, payload){
      commit('UPDATE', payload)
      if(payload.type === 'input_group'){
        commit('SUB_TOTAL', payload.key)
      }
    },
    total({commit}, val){
      commit('TOTAL', val)
    },
    edited({commit}, val){
      commit('EDITED', val)
    },
    reset({commit}){
      commit('RESET')
    }
  },
  modules: {
  }
})
