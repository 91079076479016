<template>
    <main class="main-wrapper">
        <div class="grid-row">
            <div class="grid-column-full">
                <h3>About</h3>
                <p>Officia nisi laborum ad. Dolore duis enim voluptate occaecat velit. Velit deserunt veniam fugiat nisi
                    laboris esse aute consectetur. Excepteur et sint reprehenderit.</p>
            </div>
        </div>
    </main>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'AboutView',
    components: {
    },
}
</script>