<template>
    <div class="radios">
        <radio-item 
            v-for="(item, index) in list" :key="item.id" 
            :item="item" 
            :index="index" 
            :slug="slug" 
            @select="selected"
            :checked="value[slug].list[0].value" 
        />
    </div>
</template>

<script>
/**
 * Radio group lists are passed as prop data to display the options
 * BUT only the answer is saved to the vuex store
 */
import { mapState } from 'vuex'
import RadioItem from './RadioItem.vue'
export default {
    name: 'RadioGroup',
    components: { RadioItem },
    data() {
        return {
            //value: null,
        }
    },
    props: {
        slug: {
            type: String,
            required: false
        },
        list: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapState({
            value: state => state
        }),
    },
    methods: {
        selected(key, index, value, label) {
            this.$store.dispatch('update', { type: 'radio_group', key, index, value, label })
        }
    }
}
</script>