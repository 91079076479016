<template>
    <div class="body-wrapper" id="app">
        <header-bar class="wrapper_header" />
        <div class="container content-body">
            <router-view class="wrapper_content" />
        </div>
        <footer-bar class="wrapper_footer" />

    </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import FooterBar from '@/components/FooterBar.vue'
export default {
    components: { HeaderBar, FooterBar }
}
</script>
<style lang="scss">

</style>
