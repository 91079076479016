<template>
    <div class="summary-list__row">
        <dt class="summary-list__key">
            {{ item.label }}
        </dt>
        <dd class="summary-list__value">
            <template v-if="item.type === 'radio'">
                {{item.select}}
            </template>
            <template v-else>
                {{currFormat(item.value)}}
            </template>
        </dd>
        <dd class="summary-list__actions">
            <a href="" @click="change">Change</a>
        </dd>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { calculations } from '@/mixins/calculations'
export default {
    mixins: [calculations],
    components: {
    },
    data() {
        return {
            label: "Change",
            edit: false,
        }
    },
    props: {
        page: {
            type: Number,
            required: false
        },
        item: {
            type: Object,
            required: true
        },
        symbol: {
            type: String,
            required: false
        }
    },
    computed: {
        ...mapGetters({
            state: 'state',
        }),
    },
    methods: {
        change() {
            this.calculate
            this.$router.push({ name: 'calculator', params: { question: this.page }})
        },
    }
}
</script>

<style lang="scss">

</style>