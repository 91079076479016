import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

window.GOVUKFrontend = require('govuk-frontend').initAll();

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() {
		this.$store.commit('initialiseStore');
	},
  created() {
    this.$store.subscribe((mutation, state) => {
      localStorage.setItem('store', JSON.stringify(state));
    })
  },
  render: h => h(App)
}).$mount('#app')


