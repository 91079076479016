<template>
    <main class="main-wrapper">
        <div class="grid-row">
            <div class="grid-column-full">
                <section v-for="(section, index) in sections" :key="'section_'+index">
                    <h3>{{ section.title }}</h3>
                    <p>{{ section.desc }}</p>

                    <ul class="bullet" v-if="section.list">
                        <li v-for="(item, index) in section.list" :key="index"><span v-if="item.tag" class="tag tag--blue">{{ item.tag }}</span> {{ item.item }}</li>
                    </ul>
                </section>

                <a href="#" @click.prevent="$router.push({ name: 'calculator', params: { 'question': 1 }})" role="button" draggable="false"
                    class="button--start">
                    Start now
                    <svg class="button--start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19"
                        viewBox="0 0 33 40" aria-hidden="true" focusable="false">
                        <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
                    </svg></a>
            </div>
        </div>
    </main>


</template>

<script>
// @ is an alias to /src
export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            sections: {
                1: {
                    title: 'What does this calculator do?',
                    desc: 'You can use this service to:',
                    list: [
                        { item: 'Understand who should legally get what when someone has died intestate (without a will).' },
                        { item: 'Find out who would be legally entitled to your all of your assets (if you died without a will).', tag: 'Coming soon' }
                    ]
                },
                2: {
                    title: 'How long will it take?',
                    desc: 'For most people this will take around ten minutes to complete.',
                },
                3: {
                    title: 'What will you need?',
                    desc: 'You will need to know about the person\'s:',
                    list: [
                        { item: 'Relationships' }, //(eg any children or partners)' },
                        { item: 'Property '}, //(eg any houses, flats or buildings they own)' },
                        { item: 'Valuables'}, // (eg, jewellery or high value items)' },
                        { item: 'Money and debts'} //(eg, mortgages, bank accounts etc)' }
                    ]
                }
            }
        }
    },
    mounted() {
        localStorage.clear()
    }
}
</script>