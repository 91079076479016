import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import CalculatorView from '../views/CalculatorView.vue'
import SummaryView from '../views/SummaryView.vue'
import ResultsView from '../views/ResultsView.vue'
import HttpStatus404 from '../views/HttpStatus404.vue'

Vue.use(VueRouter)

// Default application title
// Set per page in router/index
const appTitle = 'Inheritance calculator';
Vue.prototype.$htmlTitle = appTitle;


const routes = [
    {
        path: '/',
        name: 'home',
        meta: { title: appTitle },
        component: HomeView
    },
    {
        path: '/about',
        name: 'about',
        meta: { title: appTitle },
        component: AboutView
    },
    {
        path: '/policy/privacy',
        name: 'policy-privacy',
        meta: { title: appTitle },
        component: PrivacyPolicyView
    },
    {
        path: '/calculator/:question',
        name: 'calculator',
        meta: { title: `Details - ${appTitle}` },
        component: CalculatorView
    },
    {
        path: '/summary',
        name: 'summary',
        meta: { title: `Summary - ${appTitle}` },
        component: SummaryView
    },
    {
        path: '/results',
        name: 'results',
        meta: { title: `Results - ${appTitle}` },
        component: ResultsView
    },
    {
        path: '/:pathMatch(.*)*',
        meta: { title: `404 - ${appTitle}` },
        component: HttpStatus404
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const edit = (from.path === '/summary') ? true : false
    store.dispatch('edited', edit)
    next()
})

router.afterEach((to) => {
    // Update document title after each move
    Vue.nextTick(() => {
        document.title = to.meta.title || appTitle;
    });
});

export default router
