<template>
    <footer class="footer" role="contentinfo">
        <div class="container">
            <div class="footer__meta">
                <div class="footer__meta-item">
                    <span>
                        <strong>Powered by <img src="@/assets/img/Amiqus-Full@2x.png" class="logo-amiqus" />
                        in partnership with <img src="@/assets/img/uni_glasgow_logo.png" class="logo-uog" />
                    </strong></span>
                    <p><small>Version {{ appVersion }}</small></p>
                    <ul class="footer__inline-list">
                        <li class="footer__inline-list-item">
                            <a href="/" @click.prevent="$router.push({ name: 'policy-privacy' })" class="footer__link">Privacy policy</a>
                        </li>
                        <li class="footer__inline-list-item">
                            <a href="/" @click.prevent="$router.push({ name: 'about' })" class="footer__link">About</a>
                        </li>
                        <li class="footer__inline-list-item"></li>
                    </ul>

                    <!--<span class="description">Version {{ appVersion }} &mdash; This is a new service, your <a
                            href="#feedback">feedback</a> will help us to improve it.</span>-->
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { version } from '../../package'

export default {
    data() {
        return {
            appVersion: version
        }
    }
}
</script>