<template>
    <div class="list">
        <div class="list__items">
            <input-item 
                v-for="(item, index) in list" 
                :item="item" 
                :key="index"
                :index="index"
                :slug="slug" 
                :canEdit="false"
                @remove="removeItem(slug, index)"
            />
        </div>
        <button v-if="button" type="button" @click="addItem()" class="btn--link"><span>+</span>{{button.cta}}</button>
    </div>
</template>

<script>
import InputItem from './InputItem.vue'

    export default {
        components: { InputItem },
        props:{
            slug:{
                type: String,
                required: false
            },
            list:{
               type: Array,
               required: true 
            },
            button: {
                type: Object,
                required: false,
            }
        },
        data () {
            return {
                item: null,
                template: {}
            }
        },
        methods:{
            /**
             * We add a item based on the first child
             * This is a limitation when different items exists in the listItemArray
             */
            addItem() {
                const item = JSON.parse(JSON.stringify(this.list[0]))
                item.value = 0
                const payload = {'key': this.slug, 'item': item}
                this.$store.dispatch('addItem', payload)
            },
            /**
             * 
             * @param {*} id 
             */
            removeItem(slug, index) {
                const payload = {'key': this.slug, 'index': index}
                this.$store.dispatch('removeItem', payload)
            }
        }   
    }
</script>

<style lang="scss" scoped>
.list {
    &__items{
        & > *:not(:last-child){
            margin: 0 0 20px 0;
        }
    }
    button{
        margin-top: 6px;
    }
}

</style>