<template>
    <div class="radios__item">
        <input 
            class="radios__input" 
            :id="slug + index" 
            :name="slug" type="radio"
            @click="$emit('select', slug, index, index, item.label )" 
            :disabled="!item.enabled"
            :checked="checked == index"
        />
        <label class="label radios__label" :for="slug + index">
            {{item.label}}
        </label>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        item: {
            type: Object,
            required: false,
        },
        index: {
            type: Number,
            required: true,
        },
        slug: {
            type: String,
            required: true
        },
        checked: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            value: state => state
        }),

    },
    methods: {
    }
}
</script>