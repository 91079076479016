<template>
    <main class="calculator main-wrapper">
        <div class="navigation">
            <div class="navigation__back">
                <a href="" v-show="currentQuestionId > 1" class="back-link" @click.stop="previous">Back</a>
            </div>
            <div class="navigation__paginator">
                <pagination-component :total="questions.length" :current="currentQuestionId" />
            </div>
        </div>

        <div class="grid-row">
            <div class="grid-column-two-thirds">
                <section v-for="(question, index) in questions" :key="question.id">
                    <question-component v-if="currentQuestionId === index + 1" :question="question"
                        :data-is="disableButton(question.slug)" />
                </section>
                <div class="button-group">
                    <button class="button" :disabled="disabled" :aria-disabled="disabled" type="button" data-module="button"
                        @click="next">
                        Continue
                    </button>
                    <button v-show="edited" class="button button--secondary" :disabled="disabled" :aria-disabled="disabled"
                        type="button" data-module="button" @click="summary">
                        Back to summary
                    </button>
                </div>
                <div class="calculator__startover">
                    <a href="/" @click.prevent="reset">Start over</a>
                </div>

            </div>
            <div class="grid-column-one-third">
                <div class="calculator__sidebar" v-if="currentQuestion.sidebar">
                    <h4>Information</h4>
                    <ul>
                        <li v-for="(item, index) in currentQuestion.sidebar" :key="index">
                            <a :href="item.href" target="_blank">{{ item.label }}</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { calculations } from '@/mixins/calculations'
import QuestionComponent from '@/components/QuestionComponent.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'

export default {
    name: 'CalculatorView',
    mixins: [calculations],
    components: {
        QuestionComponent,
        PaginationComponent,
    },
    data() {
        return {
            current: 0,
            goto: 1,
            disabled: false,
            questions: [
                {
                    id: 1,
                    title: 'Did this person have a living spouse or civil partner?',
                    inset: 'If this person was unmarried or cohabiting with a partner, answer \'No\'.',
                    accordian: [
                    {
                            id: 0,
                            label: 'Information about cohabitants',
                            desc: 'Cohabitants have no automatic right to a share of the estate. However, they may be able to make a claim against the deceased\'s estate by applying to the courts.'
                        },
                    ],
                    slug: 'partner',
                    label: 'Partner',
                    category: 'question',
                    radio_group: {
                        list: [
                            {
                                id: 0,
                                goto: 1,
                                category: 'question',
                                enabled: true,
                                label: 'Yes',
                            },
                            {
                                id: 1,
                                goto: 1,
                                category: 'question',
                                enabled: true,
                                label: 'No',
                            },
                        ]
                    }
                },
                {
                    id: 2,
                    title: 'Did this person have living children or grandchildren?',
                    slug: 'children',
                    label: 'Children or grandchildren',
                    category: 'question',
                    radio_group: {
                        list: [
                            {
                                id: 0,
                                goto: 1,
                                category: 'question',
                                enabled: true,
                                label: 'Yes',
                            },
                            {
                                id: 1,
                                goto: 2,
                                category: 'question',
                                enabled: true,
                                label: 'No',
                            },
                        ]
                    }
                },
                {
                    id: 3,
                    title: 'If this person owned a property, what is the value of the property?',
                    slug: 'property_value',
                    inset: 'If the person did not own a property leave the default values below as zero.',
                    sidebar: [
                        { label: 'Registers of Scotland', href: 'https://scotlis.ros.gov.uk' },
                        { label: 'Rightmove Sold House Prices', href: 'https://www.rightmove.co.uk/house-prices.html' },
                        { label: 'Zoopla Sold House Prices', href: 'https://www.zoopla.co.uk/house-prices' },
                    ],
                    accordian: [
                    {
                            id: 0,
                            label: 'Where can I find the property value?',
                            desc: 'If you do not know the property value, enter an estimated value. You can find out the value of a property by asking an estate agent, looking at certain documentation (e.g. mortgage agreements) or by using an online tool.'
                        },
                        {
                            id: 1,
                            label: 'What about joint ownership?',
                            desc: 'If the property was jointly owned with another person, enter values for the amount owned by the deceased. For example, a married couple with each person named on the title deed typically owns 50% each.'
                        },
                    ],
                    input_group: {
                        list: [
                            {
                                id: 0,
                                label: 'Property value',
                                type: 'number',
                                category: 'asset',
                                value: 0
                            },
                            {
                                id: 1,
                                label: 'Mortgage remaining',
                                type: 'number',
                                category: 'debt',
                                value: 0
                            },
                        ]
                    },
                },
                {
                    id: 4,
                    title: 'What is the value of this this person\'s home contents?',
                    slug: 'home_contents',
                    sidebar: [
                        { label: 'Admiral Contents Calculator', href: 'https://www.admiral.com/home-insurance/contents-calculator' },
                        { label: 'National average contents value', href: 'https://www.admiral.com/magazine/guides/home/how-much-contents-insurance-will-i-need' },
                    ],
                    accordian: [
                        {
                            id: 0,
                            label: 'How can I find this?',
                            desc: 'If the person had home or home contents insurance, an accurate figure might be available. Alternatively, You can approach a company who provide valuations in order to be accurate or enter an estimated value to get a rough idea.'
                        },
                        {
                            id: 1,
                            label: 'What is considered home contents?',
                            desc: 'Think about this how you might when taking out a home contents insurance policy. For example, include clothing, books, artwork, prints and soft furnishings and furniture. Do not include valuables (e.g. jewellery, watches).'
                        },
                    ],
                    input_group: {
                        list: [
                            {
                                id: 0,
                                label: 'Home Contents',
                                desc: null,
                                type: 'number',
                                category: 'asset',
                                value: 0
                            }
                        ]
                    },
                },
                {
                    id: 5,
                    title: 'Finances (excluding debt)',
                    slug: 'persons_money',
                    accordian: [
                    {
                            id: 0,
                            label: 'How do I calculate this?',
                            desc: 'Add up the balances across all this person\'s accounts including any stocks or bonds owned, and any physical cash held.',
                            type: 'number'
                        },
                        {
                            id: 1,
                            label: 'What about life insurance or income protection?',
                            desc: 'If the deceased held insurance policies where the benficiary was not named, they will be valued as part of the estate and should be entered under \'Other assets\'. If a beneficiary is named, they are not considered part of the estate and should be excluded.',
                        },
                    ],
                    input_group: {
                        list: [
                            {
                                id: 0,
                                label: 'Cash',
                                desc: 'Including bank accounts, cash or cash equivalents',
                                type: 'number',
                                category: 'asset',
                                value: 0
                            },
                            {
                                id: 1,
                                label: 'Valuables',
                                desc: 'For example jewellery, works of art or precious collections',
                                type: 'number',
                                category: 'asset',
                                value: 0
                            },
                            {
                                id: 2,
                                label: 'Other assets',
                                desc: 'For example stocks and bonds',
                                type: 'number',
                                category: 'asset',
                                value: 0
                            }
                        ]
                    }
                },
                {
                    id: 6,
                    title: 'Debt (excluding mortgages)',
                    slug: 'persons_debt',
                    accordian: [
                        {
                            label: 'How do I calculate this?',
                            desc: 'You can contact the known banks and credit providers involved, use a third party tracing service or enter an estimated value to get a rough idea.'
                        },
                        {
                            label: 'What is included?',
                            desc: 'This includes any standard securities given, unsecured or secured loans, credit cards, overdrafts or store credit cards or other unpaid debts.'
                        },
                    ],
                    input_group: {
                        list: [
                            {
                                id: 0,
                                label: 'Loans',
                                type: 'number',
                                category: 'debt',
                                value: 0
                            },
                            {
                                id: 1,
                                label: 'Credit cards',
                                type: 'number',
                                category: 'debt',
                                value: 0
                            },
                            {
                                id: 2,
                                label: 'Outstanding taxes or utility bills',
                                type: 'number',
                                category: 'debt',
                                value: 0
                            },
                            {
                                id: 3,
                                label: 'Funeral expenses',
                                type: 'number',
                                category: 'debt',
                                value: 0
                            }
                        ]
                    }
                },
            ],
            prevRoute: null,
        }
    },
    computed: {
        ...mapActions({
            addState: 'addState',
        }),
        ...mapGetters({
            edited: 'edited',
            state: 'state'
        }),
        currentQuestionId() {
            return Number(this.$route.params.question)
        },
        currentQuestion() {
            return this.questions[this.currentQuestionId - 1];
        }
    },
    created() {
        this.current = this.currentQuestionId

        // If question null or 0
        if (!this.current) {
            this.reset()
            return
        }

        this.$watch(
            () => this.$route.params,
            () => {
                this.current = this.currentQuestionId
            }
        )
        const store = JSON.parse(localStorage.getItem('store'))
        if (!store.partner) {
            /* eslint-disable no-unused-vars */
            const items = this.questions.filter((question) => {
                /* Single answer */
                if (question.radio_group) {
                    let obj = {
                        'list': [{
                            'value': -1,
                            'slug': question.slug,
                            'label': question.label,
                            'type': 'radio',
                            'index': 0
                        }
                        ]
                    }
                    this.$store.dispatch('addState', [question.slug, obj])
                }
                /* Input answer */
                if (question.input_group) {
                    let obj = { 'category': '', 'list': [], 'sub_total': 0 }
                    this.$store.dispatch('addState', [question.slug, obj])
                    const list = question.input_group.list.filter((item, index) => {
                        item.slug = question.slug
                        item.index = index
                        this.$store.dispatch('init', { category: question.category, key: question.slug, value: item })
                    })
                }
            })

            // this.$router.push('/')
        }
    },
    methods: {
        disableButton(slug) {
            //Check to see if a radio has been selected
            if (this.state[slug].list[0].type === 'radio') {
                this.disabled = (this.state[slug].list[0].value === -1) ? true : false
            }

            //Check to see if inputs are positive integers
            if (this.state[slug].list[0].type === 'number') {
                const arr = []
                for (let item in this.state[slug].list) {
                    if (this.state[slug].list[item].value >= 0) {
                        arr.push(false)
                    } else {
                        arr.push(true)
                    }
                }

                const find = function (array, val) {
                    return array.some(function (bol) {
                        return bol === val;
                    });
                };

                this.disabled = find(arr, true)
            }
        },
        next() {
            if (this.current < this.questions.length) {
                this.current++
                this.$router.push({ name: 'calculator', params: { question: this.current } })
            } else {
                this.$router.push({ name: 'summary' })
            }
        },
        previous() {
            this.current = this.currentQuestionId
            this.current--
            this.$router.push({ name: 'calculator', params: { question: this.current } })
        },
        summary() {
            this.$router.push({ name: 'summary' })
        },
        reset() {
            this.current = 1
            this.$router.push({ name: 'home' })
        }
    }
}
</script>

<style lang="scss" scoped>
.calculator {
    .navigation {
        display: flex;
        justify-content: space-between;
        min-height: 65px;

        &__paginator {
            width: 225px;
        }
    }

    &__startover {
        text-align: right;
    }
}

.button-group {
    margin-top: 35px;
}
</style>