<template>
    <div class="form-group">
        <fieldset class="fieldset">
            <legend class="fieldset__legend">
                <h2>{{question.title}}</h2>
            </legend>
            <div v-if="question.inset">
                <inset-text :text="question.inset" />
            </div>
            <div v-if="question.accordian">
                <accordian-menu :options="question.accordian" />
            </div>
            <div v-if="question.radio_group">
                <radio-group :slug="question.slug" :list="question.radio_group.list" />
            </div>
            <div v-if="question.input_group">
                <input-group :slug="question.slug" :list="question.input_group.list" />
            </div>
        </fieldset>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AccordianMenu from '@/components/AccordianMenu.vue'
import InsetText from '@/components/InsetText.vue'
import RadioGroup from '@/components/RadioGroup.vue'
import InputGroup from '@/components/InputGroup.vue'

export default {
    name: 'QuestionComponent',
    props: {
        question: {
            type: Object,
            required: true
        }
    },
    components: {
        AccordianMenu,
        InsetText,
        RadioGroup,
        InputGroup,
    },
    mounted() {
        //emit event on mounted to disable buttons for questions that require answers
        this.$emit('disable-button', true);
    },
    computed: {
        ...mapActions([
            'update'
        ]),
        ...mapGetters({
            state: 'state'
        }),
    }
}
</script>

<style lang="scss" scoped>
.question {
    &>* {
        margin-bottom: 3rem;
    }
}

</style>