<template>
    <div class="summary main-wrapper">
        <div class="navigation">
            <div class="navigation__back">
                <a href="" class="back-link" @click="$router.push({ name: 'summary'})">Back</a>
            </div>
        </div>

        <div class="grid-row margin-bottom-9">
            <div class="grid-column-full">
                <div class="margin-bottom-9">
                    <h2>How should the estate be divided?</h2>
                    <h3>Net estate value: {{ currFormat(total) }}</h3>
                </div>
                <section v-if="inheritance.spouse.total > 0" class="grid-column-one-half">
                    <div>
                        <h3>Spouse or partner</h3>
                        <p>The deceased's surviving spouse or partner is entitled to the {{inheritance.spouse.percent}}% of the total value of the estate</p>
                        <dl class="summary-list">
                            <div 
                                v-for="(item, key, index) in inheritance.spouse" 
                                class="summary-list__row background--partner" 
                                :key="index"
                            >
                                <template v-if="key != 'percent'">
                                    <dt class="summary-list__key">
                                        {{key.toUpperCase()}}
                                    </dt>
                                    <dd class="summary-list__value">
                                        <strong>{{currFormat(item)}}</strong>
                                    </dd>
                                </template>
                            </div>
                        </dl>
                    </div>
                </section>
                <section v-if="inheritance.children.total > 0" class="grid-column-one-half">
                    <div>
                        <h3>Children or grandchildren</h3>
                        <p>The deceased's surviving children or grandchildren will get {{ inheritance.children.percent }}% of the rest of the remainder of the estate.</p>
                        <dl class="summary-list">
                            <div 
                                v-for="(item, key, index) in inheritance.children" 
                                class="summary-list__row background--descendants" 
                                :key="index"
                            >
                                <template v-if="key != 'percent'">
                                    <dt class="summary-list__key">
                                        {{key.toUpperCase()}}
                                    </dt>
                                    <dd class="summary-list__value">
                                        <strong>{{currFormat(item)}}</strong>
                                    </dd>
                                </template>
                            </div>
                        </dl>
                    </div>
                </section>
                <section v-if="inheritance.family.total > 0" class="grid-column-one-half">
                    <div>
                        <h3>Other family</h3>
                        <p>With no living children or grandchildren {{ inheritance.family.family }}% the family inherits of the rest of the remainder of the estate.</p>
                        <dl class="summary-list">
                            <div 
                                v-for="(item, key, index) in inheritance.family" 
                                class="summary-list__row background--descendants" 
                                :key="index"
                            >
                                <template v-if="key != 'percent'">
                                    <dt class="summary-list__key">
                                        {{key.toUpperCase()}}
                                    </dt>
                                    <dd class="summary-list__value">
                                        <strong>{{currFormat(item)}}</strong>
                                    </dd>
                                </template>
                            </div>
                        </dl>
                    </div>
                </section>         
            </div>
        </div>
        <div class="grid-row">
            <div class="grid-column-full">
                <button class="button" @click="print">Print</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { calculations } from '@/mixins/calculations'
export default {
    mixins: [calculations],
    components: {},
    computed: {
        ...mapGetters({
            state: 'state',
            total: 'total',
        }),
    },
    mounted(){
        this.calculate
    },
    methods: {
        print () {
            window.print();
        },
    }
}
</script>

<style lang="scss" scoped>
.outline{
    outline: 1px solid black;
}
section div{
    padding: 0
}
dt, dd {
    padding: 0.8rem 1rem !important;
}
dd{
    text-align: end;
}

.background{
    &--partner{
        background-color: #FDF6A3;
    }
    &--descendants{
        background-color: #ffd78a;
    }
}
</style>