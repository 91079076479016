<template>
    <div class="accordian">
        <div v-for="option in options" :key="option.id">
            <accordian-item :option="option" />
        </div>
    </div>
</template>

<script>
import AccordianItem from '@/components/AccordianItem.vue'
export default {
    name: 'AccordianMenu',
    components: {
        AccordianItem
    },
    props: {
        options: {
            type: [],
            required: true,
        }
    },
}
</script>

<style lang="scss" scoped>
.accordian:last-child {
    margin-bottom: 35px;
}
</style>