<template>
    <div>
        <header>
            <div class="container">
                <h1><a href="/" @click.prevent="$router.push({ name: 'home' })">Inheritance Calculator</a></h1>
            </div>

        </header>
        <div class="phase-banner">
            <div class="container">
                <p>
                    <strong class="tag tag--blue">Alpha</strong>
                    <span>We are testing and learning from trials of this new service</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
header {
    background: $colour-primary;
    height: 100px;

    h1 {
        @extend .govuk-heading-l;
        color: #ffffff;
        padding-top: 30px;
        font-weight: 600;

        // @TODO style properly
        a:link,
        a:visited,
        a:active,{
            text-decoration: none;
            color: #ffffff;
        }
        a:focus,
        a:hover {
            background-color: transparent;
            box-shadow: none;
            text-decoration: underline;
        }
    }
}

.phase-banner {
    background: #F0F0F4;
    padding: 10px 0;
    .tag {
        margin-right: 10px;
    }
    p {
        margin: 0;
        font-size: 1em;

    }
}
</style>