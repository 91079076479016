<template>
    <div class="pagination">
        <span class="pagination__child"
            :class="(current >= n)? 'pagination__child--active':'pagination__child--inactive'" v-for="n in total"
            :key="n"></span>
    </div>
</template>

<script>
export default {
    name: "pagination-component",
    props: {
        total: {
            type: Number,
            required: true
        },
        current: {
            type: Number,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination>*:not(:last-child) {
    margin-right: 5px;
}

.pagination {
    display: flex;
    align-content: flex-end;

    &__child {
        border: 1px solid #333A49;
        background-color: fff;
        width: 38px;
        height: 15px;

        &--active {
            background-color: #00885B;
        }

        &--inactive {
            background-color: fff;
        }
    }
}
</style>