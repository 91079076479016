import { mapGetters } from 'vuex'
export const calculations = {
    data (){
        return {
            currency:'£',
            b4: 0,
            c4: 0,
            d4: 0,
            b5: 0, // heritable after debt
            c5: 0, // contents after debt
            d5: 0, // cash after debt 
            e5: 0, // total after debt
            b7: 473000, // fixed heritable prior rights
            c7: 29000, // fixed contents prior rights
            d7: 50000, // fixed cash prior rights 50000 or 89000
            b8: 0, // heritable spouse
            c8: 0, // contents spouse
            d8: 0, // cash spouse
            b9: 0, // heritable net estate 
            c9: 0, // contents net estate
            d9: 0, // cash net estate
            c10:0,
            d10:0,
            e10:0,
            c11:0,
            b14:0,
            c14:0,
            b15:0,
            c15:0,
            b20:0,
            e20:0,
            c18:0,
            e18:0,
            c19:0,
            c20:0,
            b23:0,
            c23:0,
            b24:0,
            c24:0,
            d24:0,
            e23:0,
            e24:0,
            e25:0
        }
    },
    computed: {
        ...mapGetters ({
            items: 'getList',
            sub_total: 'getSubTotal',
            estate: 'getEstate',
            inheritance: 'getInheritance',
            //spouse:'spouse',
            partner:'partner',
            children:'children',
            state:'state',
            total:'total'
        }),
        /**
         * returns items from state[key]
         * @returns array
         */
        list() {
            return this.items(this.slug)
        },
        /**
         * Takes inputs and applies steps
         * 1. Deducts debt
         * 2. Prior rights
         * 3. Cash right
         * 4. Legal right
         * 
         * Calculations match Google sheet
         * ALL calculations should be worked out on the sheet then transferred across
         * see readme.md for link
         */
 
        calculate(){
            this.b5 = this.state['property_value'].sub_total
            this.c5 = this.state['home_contents'].sub_total
            this.d5 = this.state[`persons_money`].sub_total
            this.d7 = (this.state[`children`].list[0].value === 0)? 50000:89000
            this.e18 = (this.state[`children`].list[0].value === 0)? 3:2

            this.e5 = this.b5 + this.c5 + this.d5
            this.$store.dispatch('total', this.e5)

            //adjusts estate assets if debt exists
            if(this.state['persons_debt'].sub_total < 0) 
            {
                this.lessDebt
            }
            
            if(this.partner.value === 0) {
                this.lessPriorRights
                // cash right
                if(this.c11 < 0)
                {
                    this.b14 = 0
                }else{
                    this.cashRight
                } 
                // legal right
                this.legalRight
                
                const sum = (this.b14 == this.c14)? this.b9 - this.b14 : 0
                this.b23 = this.b8 + this.c8 + this.b14 + this.c14 + sum
                this.c23 = this.c18
                this.c24 = this.c19
                this.e20 = this.b20 + this.c20
                this.e23 = this.b23+this.c23
                this.d24 = this.e20
                this.e24 = this.c24 + this.d24
                this.e25 = this.b24+this.c24+this.d24
            }

            //displays the correct data
            //has partner and children
            if(this.partner.value === 0 && this.children.value === 0){
                this.e23 = this.b23+this.c23
                this.e24 = this.b24+this.c24+this.d24,
                this.e25 = 0
            }

            //has partner and no children
            if(this.partner.value === 0 && this.children.value === 1){
                this.e23 = this.b23+this.c23
                this.e24 = 0
                this.e25 = this.b24+this.c24+this.d24
            }

            //has children and no partner
            if(this.partner.value === 1 && this.children.value === 0){
                this.e23 = 0
                this.e24 = this.total
                this.e25 = 0
            }
            //has no partner and no children
            if(this.partner.value === 1 && this.children.value === 1){
                 this.e23 = 0
                this.e24 = 0
                this.e25 = this.total
            }

            this.inheritance.spouse = {
                total: this.e23.toFixed(2),
                percent: ((100 * this.e23.toFixed(2)) / this.e5).toFixed(2),
            };

            this.inheritance.children = {
                total: this.e24.toFixed(2),
                percent: ((100 * this.e24.toFixed(2)) / this.e5).toFixed(2),
            };
            this.inheritance.family = {
                total: this.e25.toFixed(2),
                percent: ((100 * this.e25.toFixed(2)) / this.e5).toFixed(2),
            };
        },

        /**
         * If there is moveable debt then it deducted before prior rights, cash rights or legal rights
         * Updated values are used in the rest of the calculations
         */
        lessDebt(){
            const calc_input_b4 = this.state['property_value'].sub_total
            const calc_input_b5 = this.state['home_contents'].sub_total
            const calc_input_b6 = this.state['persons_money'].sub_total
            const calc_input_b7 = this.state['persons_debt'].sub_total
            
            
            //shows each category less the deductions
            this.d4 = calc_input_b6 + calc_input_b7

            if(this.d4 < 0)
            {
                this.c4 = calc_input_b6 + calc_input_b7 + calc_input_b5
            }

            this.b4 = (this.c4 < 0)? this.b4 = calc_input_b4 + this.c4 : this.b4 = calc_input_b4
            
            // deduction from cash
            if(calc_input_b7 < this.d5){
                this.d5 += calc_input_b7
            }
            // deduction from contents
            if(this.d5 < 0){
                this.c5 += this.d5
                this.d5 = 0;
            }
            // deduction from housing
            if(this.c5 < 0){
                this.b5 += this.c5
                this.c5 = 0
            }
            this.e5 = this.b5 + this.c5 + this.d5;

            //set the total
            this.$store.dispatch('total', this.e5)
        },
        /**
         * Prior rights are ONLY applied if there is a 
         * surviving spouse or civil partner
         */
        lessPriorRights(){
            // Prior rights
            this.b8 = (this.b5 >= this.b7)? this.b7:this.b5

            if(this.c5 >= this.c7)
            {
                this.c8 = this.c7
            }else{
                this.c8 = (this.c5 <= 0)? 0: this.c5
            }
            
            if(this.d5 >= this.d7)
            {
                this.d8 = this.d7
            }else{
                this.d8 = (this.d5 <= 0)? 0: this.d5
            }
            
            // net estate
            this.b9 = (this.b5 < this.b7)? 0: this.b5 - this.b7
            this.c9 = (this.c5 < this.c7)? 0: this.c5 - this.c7
            this.d9 = (this.d5 < this.d7)? 0: this.d5 - this.d7

            this.c10 = (this.c5-this.c8 <= 0)? 0:this.c5-this.c8
            this.d10 = this.d8 + this.d9
            this.e10 = this.b9 + this.c10 + this.d10
            this.c11 = this.c10 + this.d10
        },
        /**
         * Cash right is deducted proportionally from Heritable and Moveable assets
         */
        cashRight(){
            this.b14 = (this.b9 < (this.b9/this.e10)*this.d7)? this.b9: (this.b9/this.e10)*this.d7 || 0
            this.c14 = (this.c11 < (this.c11/this.e10)*this.d7)? this.c11: (this.c11/this.e10)*this.d7 || 0
            this.b15 = this.b9 - this.b14
            this.c15 = this.c11 - this.c14
            
        },
        legalRight(){
            this.b20 = this.b15
            this.c18 = this.c15/this.e18 // e18 depends on children
            this.c19 = this.c15/this.e18 // e18 depends on children
            this.c20 = this.c15 - this.c18 - this.c19
        },
    },
    methods:{
        currFormat(val) {
            return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(val);
        }
    }
}